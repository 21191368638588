/* eslint-disable no-undef */
import React, { useState } from 'react'
import CryptoJS from 'crypto-js'
import axios from 'axios'
import { useRouter } from 'next/router'
import toast, { Toaster } from 'react-hot-toast'
import Link from 'next/link'
import Cookies from 'js-cookie'
import { redirectToHomeIfAuthenticated } from '../../utils/auth'
import toastr from 'toastr'

// Redirect if already authenticated
export async function getServerSideProps(context) {
  return redirectToHomeIfAuthenticated(context)
}

// Function to decrypt user data
const decryptData = (encryptedData, key, iv) => {
  // Giải mã dữ liệu
  const bytes = CryptoJS.AES.decrypt(
    encryptedData,
    CryptoJS.enc.Hex.parse(key),
    {
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  )

  const decryptedText = bytes.toString(CryptoJS.enc.Utf8)
  return decryptedText ? JSON.parse(decryptedText) : null
}

// Mã hóa dữ liệu
const encryptPayload = (data, key, iv) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CryptoJS.enc.Hex.parse(key),
    {
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  )
  return encrypted.toString()
}

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const router = useRouter()
  const [rememberMe, setRememberMe] = useState(false)

  const handleLogin = async (event) => {
    event.preventDefault() // Prevent form submission reload

    // Tạo khóa và IV ngẫu nhiên (có thể lưu ở đâu đó cho việc giải mã)
    const x = CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Hex) //key
    const xx = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex) //iv

    const payload = {
      email,
      password,
    }
    const bit = encryptPayload(payload, x, xx)

    try {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_SERVER_URL}/auth/login`,
        { bitData: bit, x, xx }
      )

      if (response.status === 200) {
        const { accessToken, refreshToken, user } = response.data
        // console.log('Dữ liệu mã hóa:', user.encryptedData)

        const decryptedUser = decryptData(user.bitData, user.x, user.xx)
        // console.log('Dữ liệu giải mã:', decryptedUser)

        // Store access and refresh tokens in cookies
        if (rememberMe) {
          Cookies.set('accessToken', accessToken, {
            expires: 1,
            sameSite: 'Lax',
            domain: '.wolfteam.vn',
            secure: process.env.NODE_ENV === 'production',
          })
          Cookies.set('refreshToken', refreshToken, {
            expires: 7,
            sameSite: 'Lax',
            domain: '.wolfteam.vn',
            secure: process.env.NODE_ENV === 'production',
          })
        } else {
          Cookies.set('accessToken', accessToken, {
            expires: 1,
            sameSite: 'Lax',
            domain: '.wolfteam.vn',
            secure: process.env.NODE_ENV === 'production',
          })
        }

        toast.success('Đăng nhập thành công.')
        toastr.success(
          'Đăng nhập thành công!',
          `Xin chào ${decryptedUser.username}!`
        )

        router.push('/admindashboard/homeindex')
      }
    } catch (error) {
      console.clear()

      if (error.response && error.response.status === 400) {
        const message = error.response.data.message
        toastr.error(message) // Hiển thị thông báo lỗi từ server
      } else if (error.response && error.response.status === 401) {
        const message = error.response.data.message
        toastr.error(message)
      } else if (error.response && error.response.status === 404) {
        const message = error.response.data.message
        toastr.error(message)
      } else {
        toastr.error('Đã có lỗi xảy ra, vui lòng thử lại sau.')
      }
    } finally {
      // Đây là bước quan trọng để đảm bảo việc xử lý kết thúc, bạn có thể sử dụng cho việc ẩn loader nếu cần.
      console.log('Request completed')
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin(e)
    }
  }

  return (
    <>
      <Toaster />
      <div
        className="container-scroller d-flex align-items-center justify-content-center"
        style={{
          backgroundImage: 'url(/VnUV-background.png)',
          width: '100vw',
          height: '100vh',
        }}
      >
        <div className="container-fluid ">
          <div className="d-flex align-items-center justify-content-center auth px-0">
            <div className="col-lg-4">
              <div
                className="auth-form-light text-left py-5 px-4 px-sm-5 border rounded"
                style={{ backgroundColor: 'white' }}
              >
                <div className="brand-logo text-center">
                  <img src="/workspace.png" alt="logo" />
                </div>
                <h4 className="text-center">Xin chào! hãy bắt đầu</h4>
                <h6 className="font-weight-light text-center">
                  Tiếp tục đăng nhập.
                </h6>
                <form className="pt-3" onSubmit={handleLogin}>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control form-control-lg"
                      placeholder="Địa chỉ email"
                      style={{ border: '1px solid #ccc' }}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      autoComplete="true"
                      className="form-control form-control-lg"
                      placeholder="Mật khẩu"
                      style={{ border: '1px solid #ccc' }}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label htmlFor="rememberMe" className="ml-2">
                      Ghi nhớ đăng nhập
                    </label>
                  </div>
                  <div className="mt-3">
                    <button
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      type="submit"
                    >
                      Đăng nhập
                    </button>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <Link
                      href="/forgotpassword"
                      className="auth-link text-primary"
                    >
                      Quên mật khẩu?
                    </Link>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Chưa có tài khoản?{' '}
                    <Link href="/register" className="text-primary">
                      Tạo mới
                    </Link>
                    <p>WolfTeam Corporation</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
